import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';

const Userlikecart = () => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();
  const { translate } = useTranslation();
  // Increase quantity of a product in the cart
  const increaseQuantity = async (product_id, currentQuantity, cart_id, price,qty) => {
    try {
    
        const newQuantity = currentQuantity + 1;
        if (newQuantity <= qty) {
        const newprice = price * newQuantity;

        const api_url = localStorage.getItem('api_url');
        const response = await axios.post(api_url + '/updateproductquantity5', {
          product_id,
          cart_id,
          remain_qty: newQuantity,
          price
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.data.status) {
          setCards(prevCards => prevCards.map(card =>
            card.product_id === product_id ? { ...card, quantity: newQuantity } : card
          ));
          window.location.reload();
        }
      }else{
        alert("Quantity limit is over");
      }
      
    } catch (error) {
      console.error("Error increasing quantity:", error);
    }
  };

  // Decrease quantity of a product in the cart
  const decreaseQuantity = async (product_id, currentQuantity, cart_id, price) => {
    try {
      if (currentQuantity > 1) {
        const newQuantity = currentQuantity - 1;
        const newprice = price * newQuantity;

        const api_url = localStorage.getItem('api_url');
        const response = await axios.post(api_url + '/updateproductquantity5', {
          product_id,
          cart_id,
          remain_qty: newQuantity,
          price
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.data.status) {
          setCards(prevCards => prevCards.map(card =>
            card.product_id === product_id ? { ...card, quantity: newQuantity } : card
          ));
          window.location.reload();
        }
      }else{
        alert("quantity atleast select 1 ")
      }
    } catch (error) {
      console.error("Error decreasing quantity:", error);
    }
  };

  // Handle moving item to cart
  const handleAddToCart = async (cart_id, product_id, price, quantity) => {
    try {
      const user_id = localStorage.getItem('admin_id');
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/addtocart', {
        product_id,
        user_id,
        quantity,
        price,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.status) {
        handlelikeCartremove1(cart_id);
        navigate("/Addcart", { replace: true });
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  // Handle removing item from liked items
  const handlelikeCartremove = async (cart_id, product_id, quantity) => {
    try {
      const api_url = localStorage.getItem('api_url');
      const response2 = await axios.get(api_url + '/admingetProduct/' + product_id);
      const res2 = response2.data.product;
      const remain_qty = res2.qty + quantity;

      const response1 = await axios.post(api_url + '/updateproductquantity', {
        product_id,
        remain_qty,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response1.data.status) {
        const response = await axios.get(api_url + '/removelickcart/' + cart_id);
        if (response.data.status) {
          window.location.reload(); // Consider a better way to update state or UI
        }
      }

    } catch (error) {
      console.error("Error removing from liked items:", error);
    }
  };

  // Handle removing item from liked items directly
  const handlelikeCartremove1 = async (cart_id) => {
    try {
      const api_url = localStorage.getItem('api_url');
      const response = await axios.get(api_url + '/removelickcart/' + cart_id);
      if (response.data.status) {
        window.location.reload(); // Consider a better way to update state or UI
      }
    } catch (error) {
      console.error("Error removing from liked items:", error);
    }
  };

  // Fetch liked items for the current user
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getuserlikecarts/' + user_id);
        setCards(response.data.likecartItems);
      } catch (error) {
        console.error("Error fetching liked items:", error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div>
      <h2 className="save_for">{translate('likeitems')} ({cards.length})</h2>
      <hr className="" />
      {cards.map((product) => (
        <div key={product.id}>
          <div className="order-card">
            <div className="product-info">
              <img className="product-image" src={product.product_image} alt={product.product_name} />
              <div className="product-details">
                <p className="product-name"><b>{product.product_name}</b></p>
                <p>{product.brand_name}</p>
                <p className="product-price"> ₹ {product.price}</p>
                <div className="quantity_details">
                  <div className="quantity-controls">
                    <button className="quantity-button" onClick={() => decreaseQuantity(product.product_id, product.quantity, product.cart_id, product.static_price)}>-</button>
                    <span className="quantity">{product.quantity}</span>
                    <button className="quantity-button" onClick={() => increaseQuantity(product.product_id, product.quantity, product.cart_id, product.static_price,product.pro_quantity)}>+</button>
                  </div>
                  <button className="product_save" onClick={() => handleAddToCart(product.cart_id, product.product_id, product.price, product.quantity)}> {translate('moveto')}</button>
                  <button className="product_remove" onClick={() => handlelikeCartremove(product.cart_id, product.product_id, product.quantity)}> {translate('remove')} </button>
                </div>
              </div>
              <div className="del_days">
                <p> </p>
              </div>
            </div>
          </div>
          <hr className="" />
        </div>
      ))}
      {cards.length === 0 && (
        <p className="emptycart">{translate('likeempty')}</p>
      )}
      <br /> 
    </div>
  );
};

export default Userlikecart;
