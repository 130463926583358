import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';


const Myorderdetails = ({ orderStatus, orderTime }) => {
  const [cards, setCards] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const navigate = useNavigate();
  const { translate } = useTranslation(); // Using translation hook


  const trackOrder = (order_id, cart_id) => {
    localStorage.setItem("trans_order_id", order_id);
    localStorage.setItem("trans_cart_id", cart_id);
    navigate("/Trackorder", { replace: true });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getusersorders/' + user_id);
        let filteredCards = response.data.cartItems;

        // Filter by order status
        if (orderStatus.order_placed) {
          filteredCards = filteredCards.filter(item => item.order_status === 'order_placed');
        }
        if (orderStatus.shipped) {
          filteredCards = filteredCards.filter(item => item.order_status === 'shipped');
        }
        if (orderStatus.delivered) {
          filteredCards = filteredCards.filter(item => item.order_status === 'delivered');
        }
        if (orderStatus.returned) {
          filteredCards = filteredCards.filter(item => item.order_status === 'returned');
        }

        // Filter by search input
        if (searchInput.trim() !== '') {
          filteredCards = filteredCards.filter(item =>
            item.product_name.toLowerCase().includes(searchInput.toLowerCase())
          );
        }

        setCards(filteredCards);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching card data:", error);
        setError(error); // Set error state
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchCards();
  }, [orderStatus, orderTime, searchInput]); // Include orderStatus, orderTime, and searchInput in the dependency array

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading message while fetching data
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>; // Show error message if there's an error
  }

  return (
    <div>
      <div className='search_bar'>
        <input
          type="text"
          className='search_input'
          name="search_input"
          value={searchInput}
          onChange={handleSearchInputChange}
          placeholder={translate('search')}
        />
        <button className='in_search'>{translate('search')}</button>
      </div>
      <p className='my_or1'><b>{translate('myorders')}</b></p>
      <p></p>
      <hr></hr>
      {cards && cards.map((product) => (
        <React.Fragment key={product.id}>
          <div className='order_details'>
            <div className='order_img'>
              <img src={product.product_image} alt={product.product_name} className='or_img' />
            </div>
            <div className='order_name'>
              <p className='o_na'><b>{product.product_name}</b></p>
              <p className='o_sub'><b>{product.brand_name}</b></p>
            </div>
            <div className='order_pz'>
              <p className='order_pz1'>
                {product.ori_price > 1 ? (
                  <>
                    <span className='original-price'>₹{product.pri_at}</span>
                    <span className='final-price'>₹{product.price_final}</span>
                  </>
                ) : ( 
                  <span className='final-price'>₹{product.price}</span>
                )}
              </p>
            </div>
            <div className='order_pz'>
              <p className='order_pz1'><b>{formatDate(product.date)}</b></p>
            </div>
            <div className='order_st'>
              <p className='order_st1'>{product.order_status.replace(/_/g, ' ').toUpperCase()}</p>
              <p className='order_st2' onClick={() => trackOrder(product.order_id, product.cart_id)}>Track</p>
            </div>
          </div>
          <hr></hr>
        </React.Fragment>
      ))}
      <br></br>
      <p> </p>
    </div>
  );
};

export default Myorderdetails;
